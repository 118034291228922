import styled from "styled-components";
import colors from './../../styles/colors';

export const Item = styled.a`
    color: ${colors.PenguinBlack};
    display: flex;
    width: 100%;
    height: 17%;
    background-color: #dfdfdf;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    :active{
        background-color: ${colors.LowBlue};
    }

    :focus{
        background-color: ${colors.LowBlue};
    }

    :hover {
        color: ${colors.PenguinBlack};
        background-color: #d7d7d7;
    }
`
import colors from "../../styles/colors";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import { AppBar, Avatar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";

window.addEventListener('unload', function () {
  localStorage.removeItem("navbarState")
});

type MenuProps = {
  name: string
  path: string
}

export default function NavBar(): JSX.Element {

  const navigate = useNavigate()
  const { width } = useWindowSize()
  const { user, Logout, content, getUserData } = useAuth()
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [activeButton, setActiveButton] = useState<string | null>(localStorage.getItem('navbarState'))

  useEffect(() => {
    getUserData(user?.id)
  }, [getUserData, user?.id]);

  const pagesDesktopRepresentative: MenuProps[] = [
    {
      name: 'Minha empresa',
      path: `/company/${user?.company_id}`
    },
    {
      name: 'Usuários',
      path: `/users/company/${user?.company_id}`
    },
    {
      name: 'Histórico',
      path: '/histories'
    },
    {
      name: 'Documentação',
      path: '/doc'
    },
    {
      name: 'Estatísticas',
      path: '/statistics'
    }
  ]

  const pagesDesktopAdmin: MenuProps[] = [
    {
      name: 'Empresas',
      path: '/companies'
    },
    {
      name: 'Usuários',
      path: '/userslist/'
    },
    {
      name: 'Histórico',
      path: '/histories'
    },
    {
      name: 'Documentação',
      path: '/doc'
    },
    {
      name: 'Estatísticas',
      path: '/statistics'
    }
  ]

  const pagesDesktopAdminTable: MenuProps[] = [
    {
      name: 'Planilhas',
      path: '/new-table'
    },
    {
      name: 'Empresas',
      path: '/companies'
    },
    {
      name: 'Usuários',
      path: '/userslist/'
    },
    {
      name: 'Histórico',
      path: '/histories'
    },
    {
      name: 'Documentação',
      path: '/doc'
    },
    {
      name: 'Estatísticas',
      path: '/statistics'
    }
  ]

  const pagesDesktopAdminPricing: MenuProps[] = pagesDesktopAdmin.map(item => ({ name: item.name, path: item.path }));

  pagesDesktopAdminPricing.push({
    name: 'Precificador',
    path: '/pricing'
  });

  const pagesDesktopAdminTablePricing: MenuProps[] = pagesDesktopAdminPricing.map(item => ({ name: item.name, path: item.path }));

  pagesDesktopAdminTablePricing.unshift({
    name: 'Planilhas',
    path: '/new-table'
  });

  const pagesUser = [
    {
      name: 'Histórico',
      path: `/histories`
    },
    {
      name: 'Estatísticas',
      path: '/statistics'
    }
  ]

  const settings = [
    {
      name: 'Minha conta',
      path: `/my-account/${user?.id}`
    }
  ]

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu: any = () => {
    setAnchorElNav(null);
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  }

  const somosLogo = require("../../images/horizontalLogo.png");

  const handleButtonClick = (page: any) => {
    if (page === "") { localStorage.setItem("navbarState", '') } else {
      localStorage.setItem("navbarState", page.name);
      setActiveButton(localStorage.getItem("navbarState"));
      handleCloseNavMenu();
    }
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: `${colors.DarkerGray}`, height: '81.33px', justifyContent: 'center' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link onClick={() => handleButtonClick("")} to={`/`}>
            <img src={somosLogo} width={width > 355 ? 300 : 250} alt="Logo" />
          </Link>

          {
            user?.is_admin && content?.can_manage_query_chassi_or_plate && content?.can_upload_table
              ?
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pagesDesktopAdminTablePricing.map((page) => (
                  <Button
                    key={page.name}
                    sx={{ my: 2, display: 'block', color: activeButton === page.name ? '#00AEEF' : 'white' }}
                  >
                    <Link
                      to={page.path}
                      style={{ color: activeButton === page.name ? '#00AEEF' : 'white', height: '100%', padding: '1rem 0' }}
                      onClick={() => {
                        handleButtonClick(page);
                      }}
                    >
                      {page.name}
                    </Link>
                  </Button>
                ))}
              </Box> :
              user?.is_admin && content?.can_manage_query_chassi_or_plate
                ?
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {pagesDesktopAdminPricing.map((page) => (
                    <Button
                      key={page.name}
                      sx={{ my: 2, display: 'block', color: activeButton === page.name ? '#00AEEF' : 'white' }}
                    >
                      <Link
                        to={page.path}
                        style={{ color: activeButton === page.name ? '#00AEEF' : 'white', height: '100%', padding: '1rem 0' }}
                        onClick={() => {
                          handleButtonClick(page);
                        }}
                      >
                        {page.name}
                      </Link>
                    </Button>
                  ))}
                </Box>
                : user?.is_admin && user?.can_upload_table
                  ? <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {pagesDesktopAdminTable.map((page) => (
                      <Button
                        key={page.name}
                        onClick={() => handleButtonClick(page.name)}
                        sx={{ my: 2, color: 'white', display: 'block' }}
                      >
                        <Link
                          to={page.path}
                          style={{ color: activeButton === page.name ? '#00AEEF' : 'white', height: '100%', padding: '1rem 0' }}
                          onClick={() => {
                            handleButtonClick(page);
                          }}
                        >
                          {page.name}
                        </Link>
                      </Button>
                    ))}
                  </Box>
                  : user?.is_admin
                    ? <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                      {pagesDesktopAdmin.map((page) => (
                        <Button
                          key={page.name}
                          sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                          <Link
                            to={page.path}
                            style={{ color: activeButton === page.name ? '#00AEEF' : 'white', height: '100%', padding: '1rem 0' }}
                            onClick={() => {
                              handleButtonClick(page);
                            }}
                          >
                            {page.name}
                          </Link>
                        </Button>
                      ))}
                    </Box> :
                    user?.is_representative
                      ?
                      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pagesDesktopRepresentative.map((page) => (
                          <Button
                            key={page.name}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                          >
                            <Link
                              to={page.path}
                              style={{ color: activeButton === page.name ? '#00AEEF' : 'white', height: '100%', padding: '1rem 0' }}
                              onClick={() => {
                                handleButtonClick(page);
                              }}
                            >
                              {page.name}
                            </Link>
                          </Button>
                        ))}
                      </Box>
                      : <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pagesUser.map(item => (
                          <Button
                            key={item.name}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                          >
                            <Link
                              to={item.path}
                              style={{ color: activeButton === item.name ? '#00AEEF' : 'white', height: '100%', padding: '1rem 0' }}
                              onClick={() => {
                                handleButtonClick(item);
                              }}
                            >{item.name}</Link>
                          </Button>
                        ))}
                      </Box>
          }

          {
            width > 899
              ? <Box sx={{ flexGrow: 0 }}>
                <label style={{ marginRight: 10, color: colors.IceWhite }}>
                  <strong>Bem vindo, {user?.first_name} {user?.last_name} 😃</strong>
                </label>
                <Tooltip title="Configurações">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                      <Link to={setting.path} className="btn-link">
                        <Typography textAlign="center">{setting.name}</Typography>
                      </Link>
                    </MenuItem>
                  ))}
                  <MenuItem key='sair' onClick={
                    () => {
                      Logout()
                      navigate("/")
                    }
                  }
                  >
                    <Typography textAlign="center">Sair</Typography>
                  </MenuItem>
                </Menu>
              </Box>
              : <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'end' }} >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="default"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  <label style={{ marginLeft: 10, marginRight: 10, color: colors.WaterBlue }}>
                    <strong>Bem vindo, {user?.first_name} {user?.last_name} 😃</strong>
                  </label>
                  {
                    user?.is_admin && content?.can_manage_query_chassi_or_plate && content?.can_upload_table
                      ? pagesDesktopAdminTablePricing.map((page, index) => (
                        <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                          <Link to={page.path} className="btn-link">
                            <Typography textAlign="center">{page.name}</Typography>
                          </Link>
                        </MenuItem>
                      ))
                      : user?.is_admin && content?.can_manage_query_chassi_or_plate ?
                        pagesDesktopAdminPricing.map((page, index) => (
                          <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                            <Link to={page.path} className="btn-link">
                              <Typography textAlign="center">{page.name}</Typography>
                            </Link>
                          </MenuItem>
                        ))
                        : user?.can_upload_table && user?.is_admin
                          ? pagesDesktopAdminTable.map((page, index) => (
                            <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                              <Link to={page.path} className="btn-link">
                                <Typography textAlign="center">{page.name}</Typography>
                              </Link>
                            </MenuItem>
                          ))
                          : user?.is_admin
                            ? pagesDesktopAdmin.map((page, index) => (
                              <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                <Link to={page.path} className="btn-link">
                                  <Typography textAlign="center">{page.name}</Typography>
                                </Link>
                              </MenuItem>
                            ))
                            : user?.is_representative
                              ? pagesDesktopRepresentative.map((page, index) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                  <Link to={page.path} className="btn-link">
                                    <Typography textAlign="center">{page.name}</Typography>
                                  </Link>
                                </MenuItem>
                              ))
                              : pagesUser.map((page, index) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                  <Link to={page.path} className="btn-link">
                                    <Typography textAlign="center">{page.name}</Typography>
                                  </Link>
                                </MenuItem>
                              ))
                  }
                  <MenuItem key='sair' onClick={
                    () => {
                      Logout()
                      navigate("/")
                    }
                  }
                  >
                    <Typography textAlign="center">Sair</Typography>
                  </MenuItem>
                </Menu>
              </Box>
          }
        </Toolbar>
      </Container>
    </AppBar>
  )
}
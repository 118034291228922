import Core from "../../Core"
import { useFormik } from 'formik'
import { toast } from "react-toastify"
import colors from "../../../styles/colors"
import * as api from "../../../services/api"
import { useParams } from "react-router-dom"
import { IconContext } from "react-icons/lib"
import { useCallback, useEffect } from "react"
import { GiTruck, GiBus } from 'react-icons/gi'
import PaidIcon from '@mui/icons-material/Paid'
import CardSomos from "../../../components/Card"
import useWindowSize from "../../../hooks/useWindowSize"
import HubRoundedIcon from '@mui/icons-material/HubRounded'
import { DefaultMessage } from "../../../utils/defaultMessages"
import { FaHelicopter, FaTractor, FaTrailer, FaTruck, FaRegEye } from 'react-icons/fa'
import { Alert, Button, Chip, Grid, TextField, Typography } from "@mui/material"

const colheitadeira = require("../../../images/whiteHarvest.png");

export default function CreateOrUpdateCompany(): JSX.Element {
  const { id } = useParams()
  const { width } = useWindowSize()

  const { handleChange, handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      cnpj: '',
      cep: '',
      email: '',
      number: '',
      api_user: false,
      products: [],
      fantasy_name: '',
      social_reason: '',
      can_query_chassi_or_plate: false,
      is_admin: false,
      plan: '1',
      contract_end: '',
      contract_start: '',
      tr_balance: 0,
      hp_balance: 0,
      sr_balance: 0,
      cr_balance: 0,
      cm_balance: 0,
      hv_balance: 0,
    },
    onSubmit: createOrUpdateCompany
  })

  const handleCepChange = (e: any) => {

    const { value } = e.target;

    const formattedValue = value
      .replace(/\D/g, '')
      .slice(0, 8)
      .replace(/(\d{5})(\d{3})/, '$1-$2');

    setFieldValue('cep', formattedValue.replace(/^(\d{5})(\d)/, '$1-$2'));
  };

  const handleCnpjChange = (e: any) => {

    const { value } = e.target;

    const formattedValue = value
      .replace(/\D/g, '')
      .slice(0, 14)
      .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

    setFieldValue('cnpj', formattedValue);

  };

  const handleTelefoneChange = (e: any) => {
    const { value }: any = e.target;
    // Defina o valor do campo do formulário utilizando a máscara
    setFieldValue(
      'number',
      value.replace(/\D/g, '').slice(0, 11).replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3')
    );
  };

  const addProduct = (value: string) => {
    let products: string[] = values.products
    if (products.filter(item => item === value).length > 0) {
      let index = products.indexOf(value)
      products.splice(index, 1)
      setFieldValue('products', products)

      switch (value) {
        case '1':
          setFieldValue('tr_balance', 0)
          break
        case '2':
          setFieldValue('hp_balance', 0)
          break
        case '3':
          setFieldValue('sr_balance', 0)
          break
        case '4':
          setFieldValue('cr_balance', 0)
          break
        case '5':
          setFieldValue('cm_balance', 0)
          break
        case '6':
          setFieldValue('hv_balance', 0)
          break
        default:
          { }
      }
    } else {
      products.push(value)
      setFieldValue('products', products)
    }
  }

  const getData = useCallback(async () => {
    await api.get(`company/read/${id}/`).then((response: any) => {
      setFieldValue('cnpj', response.data.content.cnpj)
      setFieldValue('cep', response.data.content.cep)
      setFieldValue('plan', response.data.content.plan)
      setFieldValue('email', response.data.content.email)
      setFieldValue('number', response.data.content.number)
      setFieldValue('api_user', response.data.content.api_user)
      setFieldValue('is_admin', response.data.content.is_admin)
      setFieldValue('can_query_chassi_or_plate', response.data.content.can_query_chassi_or_plate)
      setFieldValue('products', response.data.content.products)
      setFieldValue('tr_balance', response.data.content.tr_balance)
      setFieldValue('hp_balance', response.data.content.hp_balance)
      setFieldValue('sr_balance', response.data.content.sr_balance)
      setFieldValue('cr_balance', response.data.content.cr_balance)
      setFieldValue('cm_balance', response.data.content.cm_balance)
      setFieldValue('hv_balance', response.data.content.hv_balance)
      setFieldValue('contract_end', response.data.content.contract_end)
      setFieldValue('fantasy_name', response.data.content.fantasy_name)
      setFieldValue('social_reason', response.data.content.social_reason)
      setFieldValue('contract_start', response.data.content.contract_start)
    }).catch((error) => {
      toast.error(DefaultMessage.ERROR, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }, [id, setFieldValue])

  async function createOrUpdateCompany() {
    toast.dismiss()
    toast.loading("Aguarde...", {
      position: toast.POSITION.TOP_RIGHT,
    })

    const createUrl = `company/create/`
    const updateUrl = `company/update/${id}/`

    if (values?.tr_balance?.toString()?.length === 0) {
      setFieldValue('tr_balance', 0)
    }

    if (values?.hp_balance?.toString()?.length === 0) {
      setFieldValue('hp_balance', 0)
    }

    if (values?.sr_balance?.toString()?.length === 0) {
      setFieldValue('sr_balance', 0)
    }

    if (values?.cr_balance?.toString()?.length === 0) {
      setFieldValue('cr_balance', 0)
    }

    if (values?.cm_balance?.toString()?.length === 0) {
      setFieldValue('cm_balance', 0)
    }

    if (values?.hv_balance?.toString()?.length === 0) {
      setFieldValue('hv_balance', 0)
    }



    if (id) {
      await api.put(updateUrl, values).then((response: any) => {
        toast.dismiss()
        if (response.status === 200) {
          toast.success("Empresa atualizada com sucesso!", {
            position: toast.POSITION.TOP_RIGHT
          })
        }

        else if (response.status === 409) {
          toast.success(response.data.content, {
            position: toast.POSITION.TOP_RIGHT
          })
        } 

        else {
         
          toast.error(DefaultMessage.ERROR, {
            position: toast.POSITION.TOP_RIGHT
          })
         
        }

      }).catch((error) => {
        toast.dismiss()
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    } else {
      await api.post(createUrl, values).then((response: any) => {
        toast.dismiss()
        if (response.status === 201) {
          toast.success("Empresa cadastrada com sucesso!", {
            position: toast.POSITION.TOP_RIGHT
          })
        }
        // else if (response.status === 409) {
        //   toast.success(response.data.content, {
        //     position: toast.POSITION.TOP_RIGHT
        //   })
        // }
         else {
          toast.error(response.data.content, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      }).catch((error) => {
        toast.dismiss()
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    }
  }

  useEffect(() => {
    if (id) getData()
  }, [getData, id])

  return (
    <IconContext.Provider value={{ size: '18' }}>
      <Core
        withBlueBar
        withNavbar
        path="/companies"
        label="Clique aqui para acessar a listagem de empresas"
      >
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
          textAlign="center"
          paddingBottom={10}
        >
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              textAlign="center"
              justifyContent="space-around"
              paddingX={width > 600 ? 10 : 2}
            >
              <Grid
                item
                md={4} sm={12} xs={12}
              >
                <Typography variant="body1">
                  Razão social
                </Typography>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="social_reason"
                  name="social_reason"
                  placeholder="Razão social"
                  value={values.social_reason}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                md={4} sm={12} xs={12}
              >
                <Typography variant="body1">
                  CNPJ
                </Typography>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="cnpj"
                  name="cnpj"
                  placeholder="CNPJ"
                  value={values.cnpj}
                  onChange={handleCnpjChange}
                />
              </Grid>
              <Grid
                item
                md={4} sm={12} xs={12}
              >
                <Typography variant="body1">
                  CEP
                </Typography>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="cep"
                  name="cep"
                  placeholder="CEP"
                  value={values.cep}
                  onInput={handleCepChange}
                />
              </Grid>
              <Grid
                item
                md={4} sm={12} xs={12}
              >
                <Typography variant="body1">
                  Nome fantasia
                </Typography>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="fantasy_name"
                  name="fantasy_name"
                  placeholder="Nome fantasia"
                  value={values.fantasy_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                md={6} sm={12} xs={12}
              >
                <Typography variant="body1">
                  E-mail do responsável
                </Typography>
                <TextField
                  fullWidth
                  required
                  type="email"
                  id="email"
                  name="email"
                  placeholder="E-mail"
                  value={values.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                md={6} sm={12} xs={12}
              >
                <Typography variant="body1">
                  Telefone / Celular do responsável
                </Typography>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="number"
                  name="number"
                  placeholder="Telefone / Celular"
                  value={values.number}
                  onChange={handleTelefoneChange}
                />
              </Grid>
              <Grid
                item
                md={3} sm={12} xs={12}
              >
                <Typography variant="body1">
                  Inicio de contrato
                </Typography>
                <TextField
                  fullWidth
                  required
                  type="date"
                  id="contract_start"
                  name="contract_start"
                  value={values.contract_start}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                md={3} sm={12} xs={12}
              >
                <Typography variant="body1">
                  Término de contrato
                </Typography>
                <TextField
                  fullWidth
                  required
                  type="date"
                  id="contract_end"
                  name="contract_end"
                  value={values.contract_end}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                md={3} sm={12} xs={12}
              >
                <Typography variant="body1">
                  Permitir integração?
                </Typography>
                <Chip
                  icon={<HubRoundedIcon />}
                  label={values.api_user ? "Sim" : "Não"}
                  color={values.api_user ? "primary" : "default"}
                  sx={{ color: values.api_user ? colors.IceWhite : colors.DarkerGray }}
                  onClick={() => setFieldValue('api_user', !values.api_user)}
                />
              </Grid>

              <Grid
                item
                md={3} sm={12} xs={12}
              >
                <Typography variant="body1">
                  Pode consultar por placa ou chassi ?
                </Typography>
                <Chip
                  icon={<FaRegEye />}
                  label={values.can_query_chassi_or_plate ? "Sim" : "Não"}
                  color={values.can_query_chassi_or_plate ? "primary" : "default"}
                  sx={{ color: values.can_query_chassi_or_plate ? colors.IceWhite : colors.DarkerGray }}
                  onClick={() => setFieldValue('can_query_chassi_or_plate', !values.can_query_chassi_or_plate)}
                />
              </Grid>

              <Grid
                item
                md={3} sm={12} xs={12}
              >
                <Typography variant="body1">
                  Qual tipo de plano?
                </Typography>
                <Chip
                  icon={<PaidIcon />}
                  label={values.plan === '1' ? "Free" : values.plan === '2' ? "Pré pago" : "Pós pago"}
                  color={values.plan === '1' ? "success" : values.plan === '2' ? "primary" : "warning"}
                  sx={{ color: values.plan === '1' ? colors.IceWhite : values.plan === '2' ? colors.IceWhite : colors.DarkerGray }}
                  onClick={() => setFieldValue('plan', values.plan === '1' ? '2' : values.plan === '2' ? '3' : '1')}
                />
              </Grid>
            </Grid>

            <CardSomos>
              <Grid
                container
                textAlign="center"
                justifyContent="center"
                spacing={4}
              >
                <Grid
                  item
                  md={12} sm={12} xs={12}
                >
                  <Typography variant="h6">
                    PRODUTOS
                  </Typography>
                  <Alert
                    variant="filled"
                    severity="info"
                    icon="💡"
                  >
                    <h4>Informe abaixo a quantidade {values.plan === '1' ? 'diária' : 'mensal'} para cada produto.</h4>
                    <ol style={{ textAlign: "left" }}>
                      <li>
                        <strong>FREE:</strong> O sistema irá considerar o valor informado como créditos, que serão descontados na medida em que as consultas são realizadas, e renovado todos os dias. Quando os créditos do dia acabarem a empresa não conseguirá mais realizar consultas.
                      </li>
                      <li>
                        <strong>PRÉ PAGO:</strong> O sistema irá considerar o valor informado como <strong>créditos</strong>, que serão descontados na medida em que as consultas são realizadas, sem prazo para expirar, quando os créditos acabarem, a empresa não conseguirá mais realizar consultas.
                      </li>
                      <li>
                        <strong>PÓS PAGO:</strong> O valor informado servirá apenas como identificação do plano onde o cliente poderá acompanhar seu consumo na tela inicial. Após atingir o valor informado, a empresa ainda poderá realizar consultas, porém ciente que entrará como consulta avulsa. Uma cobrança mensal deve ser feita de acordo com o contrato firmado com o cliente, não se esqueça de emitir um <strong>boleto</strong> para pagamento todo mês.
                      </li>
                    </ol>
                  </Alert>
                </Grid>
                <Grid
                  item
                  md={4} sm={12} xs={12}
                >
                  <Chip
                    sx={{ marginX: 1, color: values.products.filter(item => item === "1").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                    icon={<FaTractor />}
                    label="Trator"
                    color={values.products.filter(item => item === "1").length > 0 ? "primary" : "secondary"}
                    onClick={() => addProduct("1")}
                  />
                  <Typography variant="body1">
                    Quantidade
                  </Typography>
                  <TextField
                    className="clientInput"
                    fullWidth
                    required
                    disabled={values.products.filter(item => item === "1").length === 0}
                    type="number"
                    id="tr_balance"
                    name="tr_balance"
                    placeholder="Informe um número de consultas aqui"
                    value={values.tr_balance}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  item
                  md={4} sm={12} xs={12}
                >
                  <Chip
                    sx={{ marginX: 1, color: values.products.filter(item => item === "2").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                    icon={<FaHelicopter />}
                    label="Helicóptero"
                    color={values.products.filter(item => item === "2").length > 0 ? "primary" : "secondary"}
                    onClick={() => addProduct("2")}
                  />
                  <Typography variant="body1">
                    Quantidade
                  </Typography>
                  <TextField
                    className="clientInput"
                    fullWidth
                    required
                    disabled={values.products.filter(item => item === "2").length === 0}
                    type="number"
                    id="hp_balance"
                    name="hp_balance"
                    placeholder="Informe um número de consultas aqui"
                    value={values.hp_balance}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  item
                  md={4} sm={12} xs={12}
                >
                  <Chip
                    icon={<FaTrailer />}
                    label="Semirreboque"
                    onClick={() => addProduct("3")}
                    color={values.products.filter(item => item === "3").length > 0 ? "primary" : "secondary"}
                    sx={{ marginX: 1, color: values.products.filter(item => item === "3").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                  />
                  <Typography variant="body1">
                    Quantidade
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    type="number"
                    id="sr_balance"
                    name="sr_balance"
                    onChange={handleChange}
                    className="clientInput"
                    value={values.sr_balance}
                    placeholder="Informe um número de consultas aqui"
                    disabled={values.products.filter(item => item === "3").length === 0}
                  />
                </Grid>
                <Grid
                  item
                  md={4} sm={12} xs={12}
                >
                  <Chip
                    icon={<FaTruck />}
                    label="Carroceria"
                    onClick={() => { addProduct("4") }}
                    color={values.products.filter(item => item === "4").length > 0 ? "primary" : "secondary"}
                    sx={{ marginX: 1, color: values.products.filter(item => item === "4").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                  />
                  <Typography variant="body1">
                    Quantidade
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    id="cr_balance"
                    name="cr_balance"
                    onChange={handleChange}
                    className="clientInput"
                    value={values.cr_balance}
                    placeholder="Informe um número de consultas aqui"
                    disabled={values.products.filter(item => item === "4").length === 0}
                  />
                </Grid>
                <Grid
                  item
                  md={4} sm={12} xs={12}
                >
                  <Chip
                    label="Caminhão e Ônibus"
                    icon={<div style={{ display: 'flex', flexDirection: 'column' }}><GiTruck /><GiBus /></div>}
                    onClick={() => { addProduct("5") }}
                    color={values.products.filter(item => item === "5").length > 0 ? "primary" : "secondary"}
                    sx={{ marginX: 1, color: values.products.filter(item => item === "5").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                  />
                  <Typography variant="body1">
                    Quantidade
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    id="cm_balance"
                    name="cm_balance"
                    onChange={handleChange}
                    className="clientInput"
                    value={values.cm_balance}
                    placeholder="Informe um número de consultas aqui"
                    disabled={values.products.filter(item => item === "5").length === 0}
                  />
                </Grid>
                <Grid
                  item
                  md={4} sm={12} xs={12}
                >
                  <Chip
                    label="Colheitadeira"
                    icon={<div style={{ display: 'flex', flexDirection: 'column' }}>{values.products.filter(item => item === "6").length > 0 ? <img width="18" height="18" src={colheitadeira} alt="harvester" /> : <img width="18" height="18" src="https://img.icons8.com/material-rounded/96/harvester.png" alt="harvester" />}</div>}
                    onClick={() => { addProduct("6") }}
                    color={values.products.filter(item => item === "6").length > 0 ? "primary" : "secondary"}
                    sx={{ marginX: 1, color: values.products.filter(item => item === "6").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                  />
                  <Typography variant="body1">
                    Quantidade
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    id="hv_balance"
                    name="hv_balance"
                    onChange={handleChange}
                    className="clientInput"
                    value={values.hv_balance}
                    placeholder="Informe um número de consultas aqui"
                    disabled={values.products.filter(item => item === "6").length === 0}
                  />
                </Grid>
              </Grid>
            </CardSomos>

            <Grid
              container
              spacing={2}
              textAlign="center"
              justifyContent="space-around"
              paddingX={width > 600 ? 20 : 2}
            >
              <Grid
                item
              >
                <Button
                  className="btn-inverse"
                  variant="contained"
                  size="large"
                  color="secondary"
                  type="submit"
                >
                  {
                    id ? "Atualizar" : "Cadastrar"
                  }
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Core>
    </IconContext.Provider>
  )
}
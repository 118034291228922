import { Doc } from "../pages/Doc"
import Histories from "../pages/Histories"
import Dashboard from "../pages/Dashboard"
import MyAccount from "../pages/User/MyAccount"
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import StatisticsScreen from "../pages/StatisticsScreen"

export default function UserRoutes(): JSX.Element {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/doc' element={<Doc />} />
        <Route path='*' element={<Dashboard />}/>
        <Route path='/' element={<Dashboard />}/>
        <Route path='/histories' element={<Histories />} />
        <Route path='/statistics' element={<StatisticsScreen />} />
        <Route path='/my-account/:id' element={<MyAccount />}/>
      </Routes>
    </BrowserRouter>
  )
}
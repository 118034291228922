/* eslint-disable array-callback-return */
import Core from "../Core"
import { Item } from "./styles"
import { useRef, useState } from "react"
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import colors from './../../styles/colors'
import useWindowSize from "../../hooks/useWindowSize"
import CodeEditor from "@uiw/react-textarea-code-editor"
import { CCard, CCardBody, CCollapse } from '@coreui/react'

const browserItens = [
    { name: 'Token', header: ['Método: POST', 'Códigos dos produtos: 1 = Trator, 2 = Helicóptero, 3 = Semi reboque, 4 = Carroceria, 5 = Ônibus e Caminhão, 6 = Colheitadeira', 'Headers: {"{"Content-Type": "application/json"}"}', 'Link: https://service.tabelasomos.com.br/user/token/', 'body: {"username":"Nome do usuário", "password":"Senha do usuário"}'], code: `myHeaders = new Headers({\n   "Content-Type": "application/json"\n})\n\nfetch('https://service.tabelasomos.com.br/user/token/', {\n   method: 'POST',\n   headers: myHeaders,\n   mode: 'cors',\n   cache: 'default',\n   body:{\n       username:"Nome do usuário",\n       password:"Senha do usuário"\n   }\n}) `, text: 'Para realização da consulta, primeiramente é necessário requerir um token com um usuáio que tenha acesso a integração para confirmaçao do usuário logado, sabendo disso, para integração deverá ser realizada uma requisição como esta:', step: 1 },
    { name: 'Marca', header: ['Método: GET', 'Link: https://service.tabelasomos.com.br/table/search/mounter/"{Código do produto}"/', 'Códigos dos produtos: 1 = Trator, 2 = Helicóptero, 3 = Semi reboque, 4 = Carroceria, 5 = Ônibus e Caminhão, 6 = Colheitadeira', 'Headers: {"Content-Type": "application/json, "Authorization":"Token adquirido na rota anterior"}'], code: `//Códigos dos produtos: 1 = Trator, 2 = Helicóptero, 3 = Semi reboque, 4 = Carroceria, 5 = Ônibus e Caminhão, 6 = Colheitadeira\n\nmyHeaders = new Headers({\n   "Content-Type": "application/json"\n   "Authorization":"Token adquirido na rota anterior"\n})\n\nfetch('https://service.tabelasomos.com.br/table/search/mounter/"{Código do produto}"/', {\n   method: 'GET',\n   headers: myHeaders,\n   mode: 'cors',\n   cache: 'default'\n}) `, text: 'Após adiquirir o token, o usuário deverá requerir a listagem de Marcas para realizar a consulta, lembrando sempre de passar o token no header da requisição, que ficará assim:', step: 2 },
    { name: 'Modelo', header: ['Método: GET', 'Link: https://service.tabelasomos.com.br/table/search/codes/"{Código do produto}"/"{Código da marca desejada}"/', 'Códigos dos produtos: 1 = Trator, 2 = Helicóptero, 3 = Semi reboque, 4 = Carroceria, 5 = Ônibus e Caminhão, 6 = Colheitadeira', 'Headers: {"Content-Type": "application/json, "Authorization":"Token adquirido na rota anterior"}'], code: `//Códigos dos produtos: 1 = Trator, 2 = Helicóptero, 3 = Semi reboque, 4 = Carroceria, 5 = Ônibus e Caminhão, 6 = Colheitadeira\n\nmyHeaders = new Headers({\n   "Content-Type": "application/json"\n   "Authorization":"Token adquirido na rota anterior"\n})\n\nfetch('https://service.tabelasomos.com.br/table/search/codes/"{Código do produto}"/"{Código da marca desejada}"/', {\n   method: 'GET',\n   headers: myHeaders,\n   mode: 'cors',\n   cache: 'default'\n}) `, text: 'Após ter a Marca desejada em mãos, enviar uma das Marcas na url para que sejam listados os modelos disponíveis daquela Marca, como no código abaixo:', step: 3 },
    { name: 'Anos', header: ['Método: GET', 'Link: https://service.tabelasomos.com.br/table/search/codes/"{Código do produto}"/"{Código do modelo desejada}"/', 'Códigos dos produtos: 1 = Trator, 2 = Helicóptero, 3 = Semi reboque, 4 = Carroceria, 5 = Ônibus e Caminhão, 6 = Colheitadeira', 'Headers: {"Content-Type": "application/json, "Authorization":"Token adquirido na rota anterior"}'], code: `//Códigos dos produtos: 1 = Trator, 2 = Helicóptero, 3 = Semi reboque, 4 = Carroceria, 5 = Ônibus e Caminhão\n\nmyHeaders = new Headers({\n   "Content-Type": "application/json"\n   "Authorization":"Token adquirido na rota anterior"\n})\n\nfetch('https://service.tabelasomos.com.br/table/search/codes/"{Código do produto}"/"{Código do modelo desejada}"/', {\n   method: 'GET',\n   headers: myHeaders,\n   mode: 'cors',\n   cache: 'default'\n}) `, text: 'Após ter adquirido o modelo desejado, irá faltar somente listar os anos, assim, tendo todas as informações necessárias para fazer a consulta de preço, abaixo um código exemplo da listagem de anos referente ao modelo escolhido:', step: 4 },
    { name: 'Consulta', header: ['Método: GET', 'Link: https://service.tabelasomos.com.br/table/search/model/price/"{Código do produto}"/"{Código do modelo desejada}"/"{Ano desejado}"/', 'Códigos dos produtos: 1 = Trator, 2 = Helicóptero, 3 = Semi reboque, 4 = Carroceria, 5 = Ônibus e Caminhão, 6 = Colheitadeira', 'Headers: {"Content-Type": "application/json, "Authorization":"Token adquirido na rota anterior"}'], code: `//Códigos dos produtos: 1 = Trator, 2 = Helicóptero, 3 = SemiReboque, 4 = Carroceria, 5 = Caminhão\n\nmyHeaders = new Headers({\n   "Content-Type": "application/json"\n   "Authorization":"Token adquirido na rota anterior"\n})\n\nfetch('https://service.tabelasomos.com.br/table/search/model/price/"{Código do produto}"/"{Código do modelo desejada}"/"{Ano desejado}"/', {\n   method: 'GET',\n   headers: myHeaders,\n   mode: 'cors',\n   cache: 'default'\n}) `, text: 'Após adquirir todas informações necessárias (Token, Marca, Modelo e Ano), basta fazer a requisição para realizar a consulta de preço enviando seguindo o padrão e rota abaixo:', step: 5 }
]

export function Doc() {

    const { width } = useWindowSize()
    const [open, setOpen] = useState(false)
    const [currentStep, setCurrentStep] = useState(1)
    const [code, setCode] = useState<string>(`myHeaders = new Headers({\n   "Content-Type": "application/json"\n})\n\nfetch('https://service.tabelasomos.com.br/user/token/', {\n   method: 'POST',\n   headers: myHeaders,\n   mode: 'cors',\n   cache: 'default',\n   body:{\n       username:"Nome do usuário",\n       password:"Senha do usuário"\n   }\n}) `);

    const textRef = useRef();

    return (
        <Core withBlueBar withNavbar>
            <div style={{ width: '100%', height: '83.5vh', display: 'flex', justifyContent: width > 767 ? 'space-between' : 'flex-start', flexDirection: width > 767 ? 'row' : 'column', alignItems: width > 767 ? 'flex-start' : 'center'}}>
                {
                    width > 767
                        ? <div id="browser" style={{ paddingLeft: "5%", paddingRight: '5%', paddingTop: '10%', width: width > 1249 ? '30%' : '25%', height: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            {browserItens.map(item => {
                                return (
                                    <Item
                                        style={{ backgroundColor: item.step === currentStep ? `${colors.LoginBlue}` : "#dfdfdf" }}
                                        onClick={() => { setCurrentStep(item.step); setCode(item.code) }}
                                        key={item.step}
                                    >
                                        Passo {item.step}
                                    </Item>
                                )
                            })}
                        </div>
                        : <>
                            <Button style={{ color: `${colors.IceWhite}`, backgroundColor: `${colors.OceanDarkBlue}`, position: 'inherit', borderRadius: '40px', width: '160px', height: '40px', marginBottom: '20px' }} onClick={() => setOpen(!open)}> Ver todos os passos</Button>
                            <Drawer
                                anchor={'bottom'}
                                style={{ height: 'max-content', justifyContent: 'center', paddingBottom: '10px', alignItems: 'center' }}
                                sx={{ justifyContent: 'center', paddingBottom: '10px', alignItems: 'center' }}
                                open={open}
                                onClose={() => setOpen(false)}
                            >
                                {browserItens.map(item => {
                                    return (
                                        <div
                                            style={{ backgroundColor: item.step === currentStep ? `${colors.LoginBlue}` : "#dfdfdf", width: '200px', display: 'flex', justifyContent: 'center', height: '50px', marginTop: '18px', alignItems: 'center', borderRadius: '50px', color: item.step === currentStep ? `${colors.OceanLightBlue}` : 'black' }}
                                            onClick={() => { setCurrentStep(item.step); setCode(item.code); setOpen(false) }}
                                            key={item.step+'b'}
                                        >
                                            Passo {item.step}
                                        </div>
                                    )
                                })}
                            </Drawer>
                        </>
                }

                <div id="textArea" style={{ width: width > 767 ? '60%' : '80%', height: '60%', marginRight: width > 767 ? '5%' : '0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {browserItens.map(item => {
                        if (currentStep === item.step) {
                            return (
                                <div key={item.step}>
                                    <h1>Passo {item.step} - {item.name}</h1>
                                    <p style={{ wordBreak: "break-word", textAlign: 'center' }}>{item.text}</p>
                                    <CodeEditor
                                        value={code}
                                        //@ts-ignore
                                        ref={textRef}
                                        language="js"
                                        placeholder="Código de integração"
                                        className="code"
                                        onChange={(evn) => setCode(evn.target.value)}
                                        padding={15}
                                        style={{
                                            fontFamily:
                                                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                                            fontSize: 12,
                                            width: '100%',
                                            backgroundColor: 'none',
                                            borderRadius: '10px',
                                            overflow: 'inherit'
                                        }}
                                    />
                                    <div style={{ minHeight: '120px', marginTop: '3%', width: "100%", marginBottom:'10px' }}>
                                        <CCollapse id="collapseWidthExample" horizontal visible={true}>
                                            <CCard style={{ width: '100%', backgroundColor: '#F1F1F1' }}>
                                                <CCardBody>
                                                    {item.header?.map(item => {
                                                        return (
                                                            <div key={item}>{item}<br /></div>
                                                        )
                                                    })}
                                                </CCardBody>
                                            </CCard>
                                        </CCollapse>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        </Core>
    )
}
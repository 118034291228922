import { Doc } from '../pages/Doc'
import Users from '../pages/User/Users'
import Histories from '../pages/Histories'
import Dashboard from '../pages/Dashboard'
import Tables from '../pages/Table/Tables'
import NewTable from '../pages/Table/NewTable'
import MyAccount from '../pages/User/MyAccount'
import Companies from '../pages/Company/Companies'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import CreateOrUpdateUser from '../pages/User/CreateOrUpdateUser'
import CreateOrUpdateCompany from '../pages/Company/CreateOrUpdateCompany'
import UsersList from '../pages/User/UsersList'
import { Pricing } from '../pages/Pricing'
import PricingTable from '../pages/PricingTable'
import StatisticsScreen from '../pages/StatisticsScreen'

export default function AdminTableRoutes(): JSX.Element {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<Dashboard />}/>
        <Route path='/' element={<Dashboard />}/>
        <Route path='/histories' element={<Histories />} />
        <Route path='/new-table' element={<NewTable />}/>
        <Route path='/tables' element={<Tables />}/>
        <Route path='/companies' element={<Companies />}/>
        <Route path='/company/:id' element={<CreateOrUpdateCompany />}/>
        <Route path='/new-company' element={<CreateOrUpdateCompany />}/>
        <Route path='/users/:id' element={<Users />}/>
        <Route path='/user/:id' element={<CreateOrUpdateUser />}/>
        <Route path='/userslist/' element={<UsersList />}/>
        <Route path='/my-account/:id' element={<MyAccount />}/>
        <Route path='/new-user' element={<CreateOrUpdateUser />}/>
        <Route path='/doc' element={<Doc />} /> 
        <Route path='/pricing' element={<Pricing />} /> 
        <Route path='/pricingTable' element={<PricingTable />} /> 
        <Route path='/statistics' element={<StatisticsScreen />} />
      </Routes>
    </BrowserRouter>
  )
}